import { Link } from "react-router-dom"
import { BeakerIcon } from "@heroicons/react/outline"

export default function MissingBlock(props) {
return (
  <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div className="max-w-max mx-auto">
      <div className="sm:flex">
        {props.sideIcon ? 
          <props.sideIcon className="h-12 w-12 text-blue-600" aria-hidden="true" /> : 
          <p className="text-4xl font-extrabold text-blue-600 sm:text-5xl">{props.sideText}</p>
         }
        
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{props.headerText}</h1>
                <p className="mt-1 text-base text-gray-500">{props.subHeaderText}</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link to='/' className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Go back home
              </Link>
              <Link to='/Contact' className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Contact us
              </Link>
            </div>
          </div>
      </div>
    </div>
  </div>
)
}