/* This example requires Tailwind CSS v2.0+ */
import {
    DocumentTextIcon,
    DesktopComputerIcon,
    PencilAltIcon,
    BellIcon,
    ArrowCircleRightIcon,
    UserAddIcon,
    CloudUploadIcon,
    CogIcon,
    LockClosedIcon,
    RefreshIcon,
    ServerIcon,
    ShieldCheckIcon,
  } from '@heroicons/react/outline'
  
  const features = [
    {
      name: 'Automatic Message Drafting',
      description: 'Our clinically trained algorithms can draft automatic responses to >25% of patient messages. All your doctors have to do is review and send.',
      icon: DocumentTextIcon,
    },
    {
      name: 'In-Window Context',
      description: 'We display relevant information from a patient’s medical record based on the content of their message so you can give an informed response without clicking all over the EHR.',
      icon: DesktopComputerIcon,
    },
    {
      name: 'Next-Sentence Suggestion',
      description: 'We help you construct messages fast by offering sentence-by-sentence suggestions based on clinical context and your messaging patterns.',
      icon: PencilAltIcon,
    },
    {
      name: 'Urgency and Sentiment Analysis',
      description: 'Messages get pre-scanned so you can quickly see urgent messages and messages from frustrated patients.',
      icon: BellIcon,
    },
    {
      name: 'One-Click Next Actions',
      description: 'Based on message contents, we pre-populate suggested actions such as refilling a prescription or scheduling a new appointment.',
      icon: ArrowCircleRightIcon,
    },
    {
      name: 'Automatic Message Routing',
      description: 'We can divert messages from your inbox by automatically sending certain types of messages to the right people on your team.',
      icon: UserAddIcon,
    },
  ]
  
  export default function IntellireplyFeatures() {
    return (
      <div className="relative bg-white py-16 sm:py-24 lg:py-32" id="Intellireply">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-base font-semibold uppercase tracking-wider text-blue-600">Intelli-reply</h2>
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Everything you need to deploy your app
          </p>
          <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          Our state-of-the-art AI uses clinically validated knowledge to draft responses to over 25% of your messaging volume. And our entire suite of tools makes responding to the other 75% faster and simpler than ever before.
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-blue-500 p-3 shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                      <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  