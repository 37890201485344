import { HashLink } from 'react-router-hash-link';
/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
export default function BannerCallToAction() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
          <div className="bg-blue-700 rounded-lg shadow-md overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  <span className="block">Ready to see it in action?</span>
                  <span className="">Sign up for our</span>{' '}
                  <span className="italic underline">free</span>{' '}
                  <span className="block">pilot program.</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-blue-200">
                  We're offering our pilot for free so you can see for yourself how much time our technology could save your clinic. No costs or changes to your current workflows - we promise.
                </p>
                <HashLink
                smooth
                to="/Products#ONRPilot"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-blue-600 hover:bg-blue-50"
                >
                
                  Learn about our O&R Pilot
                </HashLink>
              </div>
            </div>
            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <img
                className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                src="https://tailwindui.com/img/component-images/full-width-with-sidebar.jpg"
                alt="App screenshot"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  