import johnKirby from '../assets/people/johnKirby.png'
import rickEpiscopo from '../assets/people/rickEpiscopo.png'
import lizCormierMay from '../assets/people/lizCormierMay.png'
import robCronin from '../assets/people/robCronin.png'
import erinWolff from '../assets/people/erinWolff.png'
import michelleRoach from '../assets/people/michelleRoach.png'

/* This example requires Tailwind CSS v2.0+ */
const people = [
    {
      name: 'John Kirby',
      role: 'Boat School Ventures, Founder',
      image: johnKirby
    },
    {
      name: 'Liz Cormier-May',
      role: 'Mammogen, Founder & CEO',
      image: lizCormierMay
    },
    {
      name: 'Dr. Rob Cronin',
      role: 'Ohio State Medicine, Physician & Professor',
      image: robCronin
    },
    {
      name: 'Dr. Erin Wolff',
      role: 'Pelex, Founder & Physician',
      image: erinWolff
    },
    {
      name: 'Rick Episcopo',
      role: 'Proepics Capital Group, CEO',
      image: rickEpiscopo
    },
    {
      name: 'Michelle Roach',
      role: 'Vanderbilt Medicine, Physician & Professor',
      image: michelleRoach
    }
  ]
  
  export default function AdvisorGrid() {
    return (
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet Our Advisors</h2>
              <p className="text-xl text-gray-500">
                An accomplished group of entrapreneurs and physicians, we're fortunate to have this group in our corner.
              </p>
            </div>
            <div className="lg:col-span-2">
              <ul role="list" className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                {people.map((person) => (
                  <li key={person.name}>
                    <div className="flex items-center space-x-4 lg:space-x-6">
                      <img className="w-16 h-16 rounded-full lg:w-20 lg:h-20" src={person.image} alt="" />
                      <div className="font-medium text-lg leading-6 space-y-1">
                        <h3>{person.name}</h3>
                        <p className="text-blue-600">{person.role}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
  