import vumc from '../assets/logos/vumc.png';
import osuwmc from '../assets/logos/osuwmc.png';
import nsf from '../assets/logos/nsf.png';
import bsv from '../assets/logos/bsv.png';
import mosaic from '../assets/logos/mosaicDataScience.png'

/* This example requires Tailwind CSS v2.0+ */
const partners  = [
  {
    key: 0,
    name: 'Boat School Ventures',
    logo: bsv, // PPT -> Color: blue accent color 5 light
    url: 'https://www.boatschoolventures.com/'
  },
  {
    key: 1,
    name: 'National Science Foundation',
    logo: nsf,
    url: 'https://nsf.gov/'
  },
  {
    key: 2,
    name: 'Vanderbilt University Medical Center',
    logo: vumc,
    url: 'https://www.vumc.org/main/home',
  },
  // {
  //   key: 3,
  //   name: 'Ohio State University Wexner Medical Center',
  //   logo: osuwmc,
  //   url: 'https://wexnermedical.osu.edu/'
  // },
  {
    key: 4,
    name: 'Mosaic Data Science',
    logo: mosaic,
    url: 'https://mosaicdatascience.com/'
  }
]

export default function PartnersLogoCloud() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
            Our partners, collaborators, and investors
          </p>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-8 mx-auto">
          {partners.map((partner) => (
            <div key={partner.key} className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <a href={partner.url} target="_blank" rel="noopener noreferrer">
                <img
                  className="max-h-12"
                  src={partner.logo}
                  alt={partner.name}
                />
              </a>
            </div>
          )
          )}
            
           
          </div>
        </div>
      </div>
    )
  }
  