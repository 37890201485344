// import Home from "./Home"
// import Contact from "./Contact"
import Header from "../components/Header"
import Footer from "../components/Footer"
import MissingBlock from "../components/MissingBlock"
import { BeakerIcon } from "@heroicons/react/outline"
// import { Link } from "react-router-dom"


/* This example requires Tailwind CSS v2.0+ */
export default function Status() {
    return (
      <main>
        <Header />
        <MissingBlock 
          sideText={null}
          sideIcon={BeakerIcon}
          headerText="Still in the lab"
          subHeaderText="We're perfecting our Service Status page. In the mean time ..."
        />
        <Footer />
      </main>
    )
  }
  