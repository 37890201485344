/* This example requires Tailwind CSS v2.0+ */
import {
    AdjustmentsIcon,
    BadgeCheckIcon,
    ChatAltIcon,
    CogIcon,
    DesktopComputerIcon,
    DocumentReportIcon,
    DocumentSearchIcon,
    FilterIcon,
    HeartIcon,
    IdentificationIcon,
    InboxIcon,
    InboxInIcon,
    LightningBoltIcon,
    PencilAltIcon,
    ReplyIcon,
    SupportIcon,
    TrashIcon,
    UserGroupIcon,
    UsersIcon,
  } from '@heroicons/react/outline'
  
  const features = [
    {
      name: 'A Modern UI',
      description: "Your job is so important. Why is your inbox so clunky?",
      icon: DesktopComputerIcon,
    },
    {
        name: 'Fast and responsive',
        description: "Don't let slow loading interfaces stop you anymore. Work at the speed of you.",
        icon: LightningBoltIcon,
      },
    {
      name: 'Collaborative',
      description: 'Work efficiently with the right people, when you need them.',
      icon: UserGroupIcon,
    },
    {
        name: 'Smart chart scan',
        description: 'Presenting you with the right chart information to answer messages faster.',
        icon: DocumentSearchIcon,
    },
    {
      name: 'Smart filter and sort',
      description: 'Not first in, first out -- sort by urgency, time sent, sender, topic.',
      icon: FilterIcon,
    },
    {
      name: 'Auto message routing',
      description: 'Messages automatically sent to the right person, or group of people.',
      icon: IdentificationIcon,
    },
    {
      name: 'Mesage commenting',
      description: "Don't turn a message into another message. Stop mindless message forwarding with tags and comments.",
      icon: ChatAltIcon,
    },
    {
        name: 'Fully customizable',
        description: 'Use one of our preset configurations, or change settings and interfaces to fit your needs.',
        icon: AdjustmentsIcon,
    },
  ]
  
  export default function EfficiencyFeatures() {
    return (
      <div className="bg-blue-700" id="Efficiency">
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
          <h2 className="text-base text-white font-semibold tracking-wide uppercase">Efficiency Suite</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            A modern clinical inbox UI, finally
          </p>
          <p className="mt-4 max-w-2xl text-xl text-white">
          Bring the ease-of-use of your favorite at-home apps into the clinic with our efficiency suite, intentionally designed to help you visualize, organize, and prioritize your patient inbox. Move through messages with more speed and less stress than ever before.
          </p>
          <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name}>
                <div>
                  <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                  <p className="mt-2 text-base text-blue-200">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  