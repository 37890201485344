/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/solid'

export default function AlertBanner(props) {
    

  return (
    <div className={`rounded-md bg-${props.color}-100 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
        {props.status === 'success' ? 
            <CheckCircleIcon className={`h-5 w-5 text-${props.color}-400`} aria-hidden="true" /> : 
            <XCircleIcon className={`h-5 w-5 text-${props.color}-400`} aria-hidden="true" /> 
        }
        </div>
        <div className="ml-3">
        <h3 className={`text-sm font-medium text-${props.color}-800`}>{props.header}</h3>
          <div className={`mt-2 text-sm text-${props.color}-700`}>
            <p>{props.text}</p>
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={`inline-flex bg-${props.color}-50 rounded-md p-1.5 text-${props.color}-500 hover:bg-${props.color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${props.color}-50 focus:ring-${props.color}-600`}
              onClick={props.handleDismiss}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}