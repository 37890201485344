/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Weekly digest',
    description: 'Get a summary of all patient conversations every week to keep your whole staff up to date on each patient’s context.',
  },
  {
    name: 'Content analysis',
    description: 'Understand the categories and intents behind patient messages.',
  },
  {
    name: 'Sentiment analysis',
    description: 'Understand if your patients are happy, frustrated, confused, or anywhere in between.',
  },
  {
    name: 'Time per message',
    description: 'See how long each of your staff is spending in their inbox.',
  },
  {
    name: 'Population trends',
    description: 'Understand in aggregate what your patient base is asking about to proactively meet their needs.',
  },
  {
    name: 'Load balancing',
    description: 'See who is carrying the weight of messaging to reallocate if necessary.',
  },
  {
    name: 'Custom metrics',
    description: "Got something specific you'd like to know? Our team would be happy to help you generate a custom report."
  },
  {
    name: 'ROI',
    description: 'See exactly how much time and money Pragma saves, with real data!',
  },
]

export default function ReportingFeatures() {
  return (
    <div className="bg-white" id="Reporting">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">Advanced Reporting</h2>
          <p className="mt-4 text-lg text-gray-500">
            We provide custom administrator reports packed with easy-to-understand metrics and actionable insights. It's everything you need to know about the <span className="italic">State of Your Inbox</span>.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
