import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'
import Error from './pages/Error';
import Pricing from './pages/Pricing';
import About from './pages/About';
import Contact from './pages/Contact';
import Media from './pages/Media';
import Products from './pages/Products';
import Docs from './pages/Docs';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Status from './pages/Status';
import Careers from './pages/Careers';
import Consultation from './pages/Consultation';
import PilotSignUp from './pages/PilotSignUp';
import ReactGA from "react-ga4";
import { useEffect } from 'react';
import usePageTracking from './usePageTracking';

// import ReactGA from 'react-ga'
// import createHistory from 'history/createBrowserHistory'


export default function App() {
  usePageTracking();

  return (
    <main>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/Pricing" element={<Pricing/>} />
        <Route path="/About" element={<About/>} />
        <Route path="/Docs" element={<Docs/>} />
        <Route path="/Contact" element={<Contact/>} />
        <Route path="/Media" element={<Media/>} />
        <Route path="/Products" element={<Products/>} />
        <Route path="/Privacy" element={<Privacy/>} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Status" element={<Status />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/Consultation" element={<Consultation />} />
        <Route path="/PilotSignUp" element={<PilotSignUp />} />

        {/*<Route path="/shop" component={Shop} />
        <Route component={Error} />  */}
        <Route path="*" element={<Error/>} />
      </Routes>
    </main>
  )
};
