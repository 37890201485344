/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';
import { PopupButton } from "react-calendly";



import laptopImg from '../assets/imgs/consultingLaptop.png'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HeroWithAngledImage() {
  return (
    <div className="relative bg-gray-50">
     
      <main className="lg:relative">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Schedule a free</span>{' '}
              <span className="block text-blue-600 xl:inline">inbox consultation</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            For over a year, we’ve been working with providers to build the modern clinical inbox. But along the way, we’ve also thought deeply about best practices to help you manage your current inbox. We think a 30-minute call with us could save you hours of clinic time in the near future. So please reach out; we’d love to meet you!
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
              <PopupButton
                url="https://calendly.com/austinhardcastle/30min"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                /*
                * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                */
                rootElement={document.getElementById("root")}
                text="Schedule a call"
              />

                
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <Link to='/Contact' className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
              Reach out
              </Link>

              
                      
              </div>

              
            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={laptopImg}
            alt=""
          />
        </div>
      </main>
    </div>
  )
}
