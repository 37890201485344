/* This example requires Tailwind CSS v2.0+ */
import {
    CloudIcon,
    AdjustmentsIcon,
    ShieldCheckIcon,
    DocumentSearchIcon,
    AnnotationIcon,
    ReplyIcon,
  } from '@heroicons/react/outline'
  
  const features = [
    {
      name: 'AI Auto-Reply',
      description: "We trained our AI to answer questions in your speciality. How about automatically answering 1/3 of your patients' questions?",
      icon: ReplyIcon,
    },
    {
      name: 'Contextual Awareness',
      description: 'Each patient is unique. So we check their lab results, medications, diagnoses, upcoming procedures and more.',
      icon: DocumentSearchIcon,
    },
    {
      name: 'Smart Message Drafts',
      description: "Even if we can't answer it, we can still help by guiding your response with all of the relevant information.",
      icon: AnnotationIcon,
    },
    {
      name: 'Customizable',
      description: 'Our defaults are good, but you can dial in our tools to be exactly what your clinic needs.',
      icon: AdjustmentsIcon,
    },
    {
      name: 'Pure cloud-based',
      description: 'Seamless integration with your EHR. No IT department hassles.',
      icon: CloudIcon,
    },
    {
      name: 'Secure',
      description: "We undergo regular independent 3rd party security testing. Our products are HIPAA and SOC2 compliant, and ISO 27001 certified.",
      icon: ShieldCheckIcon,
    },
  ]
  
  export default function FeatureGrid() {
    return (
      <div className="relative bg-white py-16 sm:py-24 lg:py-32" id="LearnMore">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-base font-semibold uppercase tracking-wider text-blue-600">Inspired Features</h2>
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Your clinical inbox <span className="italic">should</span> be more advanced than your email
          </p>
          <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
            We put the right things in there, and we put them where they belong. How do we know? Because we asked you.
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-blue-500 p-3 shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                      <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  