import Header from '../components/Header'
import Footer from '../components/Footer'
import PrivacyContent from '../components/PrivacyContent'


export default function Privacy() {
    return (
        <main> 
            <Header />
            <PrivacyContent />
            <Footer />
        </main>
    )
};