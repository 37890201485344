import Header from '../components/Header'
import Footer from '../components/Footer'
import TermsContent from '../components/TermsContent'


export default function Terms() {
    return (
        <main> 
            <Header />
            <TermsContent />
            <Footer />
        </main>
    )
};