import MediaPostList from "../components/MediaPostList";
import { BeakerIcon } from "@heroicons/react/outline";
import Header from "../components/Header"
import Footer from "../components/Footer"
import MissingBlock from "../components/MissingBlock"

export default function Media() {
    return (
      <main>
        <Header />
        <MissingBlock 
          sideText={null}
          sideIcon={BeakerIcon}
          headerText="Still in the lab"
          subHeaderText="We're perfecting our Media page. In the mean time ..."
        />
        {/*TODO Update MediaPostList  */}
        {/* <MediaPostList /> */}
        <Footer />
    </main>
    )
}



