import Header from '../components/Header'
import BannerCallToAction from '../components/BannerCallToAction'
import LearnMoreCallToAction from '../components/LearnMoreCallToAction'
import SignUpBanner from '../components/SignUpBanner'
import FeatureGrid from '../components/FeatureGrid'
import Footer from '../components/Footer'
// import EstimatorGrid from '../components/EstimatorGrid'
import SimpleStats from '../components/SimpleStats'
import SimpleBanner from '../components/SimpleBanner'

export default function Home() {

    return (
        <main>

            <Header />
            
            
            <SimpleBanner />
            <SimpleStats />
            {/* <LearnMoreCallToAction /> */}
            <FeatureGrid />
            
            <BannerCallToAction />
            {/* <SignUpBanner /> */}
            <Footer />
        </main>
    )
}

