import Header from '../components/Header'
// import AboutIntro from '../components/AboutIntro'
import Footer from '../components/Footer'
import TeamGrid from '../components/TeamGrid'
import AdvisorGrid from '../components/AdvisorGrid'
import DoubleTestimonial from '../components/DoubleTestimonial'
import SingleTestimonial from '../components/SingleTestimonial'
import SimpleStackedBanner from '../components/SimpleStackedBanner'
import ContactForm from '../components/ContactForm'
import PartnersLogoCloud from '../components/PartnersLogoCloud'
import SplideTestimonial from '../components/SplideTestimonial'


export default function About() {
    return (
        <main> 
            <Header />
            {/* <SimpleStackedBanner /> */}
            {/* <SingleTestimonial /> */}
            <TeamGrid />
            <AdvisorGrid />      
            <SplideTestimonial />      
            {/* <DoubleTestimonial /> */}
            <PartnersLogoCloud />
            <Footer />
        </main>
    )
};