import ContactForm from "../components/ContactForm"
import Header from "../components/Header"
import Footer from "../components/Footer"

export default function Home() {

    return (
        <main>
            <Header />
            <ContactForm />
            <Footer />

        </main>
    )
}
