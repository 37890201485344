/* This example requires Tailwind CSS v2.0+ */
export default function SimpleStats() {
    return (
      <div className="bg-blue-800">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">We give your staff their time back</h2>
            <p className="mt-3 text-xl text-blue-200 sm:mt-4">
              See new patients. Spend more time with your current ones. Stop <span className="italic">service recovery</span>.
            </p>
          </div>
          <dl className="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-blue-200">Message Reduction</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">35%</dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-blue-200">Time Saved</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">65%</dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-blue-200">Decreased Service Recovey</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">50%</dd>
            </div>
          </dl>
        </div>
      </div>
    )
  }
  