/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { CurrencyDollarIcon,MailIcon,UserGroupIcon,ClockIcon, RefreshIcon } from '@heroicons/react/outline'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import { useState, useEffect } from 'react'

import styles from "./EstimatorForm.module.css"



export default function EstimatorForm() {

    const initialMsgFormState = {
      numberOfNurses: 15,
      numberOfDoctors: 7,
      numberOfMessagesPerMonth: 2500, 
      nurseSalary: 100, // 1000*USD
      doctorSalary: 250, // 1000*USD
      nurseTimePerMessage: 4, // minutes
      doctorTimePerMessage: 4, // minutes
      pctAnsweredByNurses: 75
    }
    const [msgFormState, setMsgFormState] = useState(initialMsgFormState)
    const [costState, setCostState] = useState([])

    var resetDefaults = () => {
      setMsgFormState(initialMsgFormState);
      calculateCosts()
    }

    var handleSliderChange = event => {
      // console.log(event)
      setMsgFormState({ ...msgFormState, [event.target.name]: event.target.value });
      calculateCosts()
      
    }

    var calculateCosts = () => {
      var fractionAnsweredByNurses = Number(msgFormState.pctAnsweredByNurses) / 100; // %
      var nurseSalaryPerHr = 1000 * Number(msgFormState.nurseSalary) / 12 / 4 / 40; // $
      var doctorSalaryPerHr = 1000 * Number(msgFormState.doctorSalary) / 12 / 4 / 40; // $

      var nurseMsgsPerMonth = (Number(msgFormState.numberOfMessagesPerMonth) * fractionAnsweredByNurses); // msgs / mo
      var doctorMsgsPerMonth = (Number(msgFormState.numberOfMessagesPerMonth) * (1 - fractionAnsweredByNurses)); // msgs / mo

      var nurseHrsPerMonth = nurseMsgsPerMonth * Number(msgFormState.nurseTimePerMessage) / 60; // hrs / mo [=] msgs / mo * hrs / msg
      var doctorHrsPerMonth = doctorMsgsPerMonth * Number(msgFormState.doctorTimePerMessage) / 60; // hrs

      var nurseCostPerMonth = nurseHrsPerMonth * nurseSalaryPerHr // $/mo [=] hrs/mo * $/hr
      var doctorCostPerMonth = doctorHrsPerMonth * doctorSalaryPerHr // $/mo [=] hrs/mo * $/hr

      // var numberOfNewNurses = nurseCostPerMonth / (nurseSalaryPerHr * 40 * 4)
      var numberOfNewNurses =  nurseCostPerMonth / (nurseSalaryPerHr * 40 * 4); // nurseHrsPerMonth / 40
      var numberOfNewDoctors = doctorCostPerMonth / (doctorSalaryPerHr * 40 * 4); // doctorHrsPerMonth / 40
      var doctorMessagesPerPersonPerMonth = doctorMsgsPerMonth / Number(msgFormState.numberOfDoctors)
      var nurseMessagesPerPersonPerMonth = nurseMsgsPerMonth / Number(msgFormState.numberOfNurses)
      var numberOfNewStaff = numberOfNewDoctors + numberOfNewNurses
      
      var messagesPerPersonPerMonth = (doctorMessagesPerPersonPerMonth + nurseMessagesPerPersonPerMonth) / 2
      var totalCostPerMonth = doctorCostPerMonth + nurseCostPerMonth
      var totalTimePerMonth = doctorHrsPerMonth + nurseHrsPerMonth

      var costs = {
        msgsPerMonth: msgFormState.numberOfMessagesPerMonth,
        fractionAnsweredByNurses:fractionAnsweredByNurses,
        nurseMsgsPerMonth: nurseMsgsPerMonth,
        doctorMsgsPerMonth: doctorMsgsPerMonth,
        messagesPerPersonPerMonth: messagesPerPersonPerMonth,
        doctorMessagesPerPersonPerMonth: doctorMessagesPerPersonPerMonth,
        nurseMessagesPerPersonPerMonth: nurseMessagesPerPersonPerMonth,
        nurseCostPerMonth: nurseCostPerMonth,
        doctorCostPerMonth: doctorCostPerMonth,
        nurseHrsPerMonth: nurseHrsPerMonth,
        doctorHrsPerMonth: doctorHrsPerMonth,
        numberOfNewNurses: numberOfNewNurses,
        numberOfNewDoctors: numberOfNewDoctors,
        numberOfNewStaff: numberOfNewStaff,
        totalCostPerMonth: totalCostPerMonth,
        totalTimePerMonth: totalTimePerMonth

      }

      setCostState(
       [
  {
    id: 1,
    name: messagesPerPersonPerMonth.toFixed(0) + ' messages per staff per month',
    description:
      doctorMessagesPerPersonPerMonth.toFixed(0) + ' messages per doctor per month, ' + nurseMessagesPerPersonPerMonth.toFixed(0) + ' messages per nurse per month. What else could your staff be doing each day?',
    icon: MailIcon,
  },
  {
    id: 2,
    name: totalCostPerMonth.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }) + ' monthly spent on just messaging',
    description: "Looking at the time and cost per person, you are paying " + doctorCostPerMonth.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }) + 
      ' for doctors and ' + nurseCostPerMonth.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }) + ' for nurses, just to work on their inbox.',
    icon: CurrencyDollarIcon,
  },
  {
    id: 3,
    name: totalTimePerMonth.toFixed(1) + ' hours per month on messaging',
    description:
      'What could your doctors do with ' + doctorHrsPerMonth.toFixed(1) + ' extra hours per month? What about giving your nurses back ' + nurseHrsPerMonth.toFixed(1) + ' hours per month?',
    icon: ClockIcon,
  },
  {
    id: 4,
    name: "The cost spent on messaging could be used for " + numberOfNewStaff.toFixed(2) + ' new staff members.',
    description:
      "That's " + numberOfNewDoctors.toFixed(2) + ' full-time doctors, and ' + numberOfNewNurses.toFixed(2) + ' full-time nurses.',
    icon: UserGroupIcon,
  },
]
      )

      // console.log(costs)

      return costs
      
    }

    useEffect(() => {
      resetDefaults();
    }, [])
    
   

   
  

    return (
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:pb-20 lg:pb-28">
        
        <div className="pt-12 sm:pt-16 lg:pt-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">How much is your time worth?</h2>
                    <p className="mt-4 text-xl text-gray-600">
                    Use our calculator to discover the savings you, and your entire clinic, could have.
                    </p>
                </div>
            </div>
        </div>
  
        <div className="mt-10 ">
          <div className="md:grid md:grid-cols-5 md:gap-6">
            <div className="md:col-span-3">
              <div className="px-4 sm:px-0">
                

              <div className="relative   lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Money, time, satisfaction
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              Inbox burden is a product of personnel, salaries, and time. The inefficiencies lead to missed opportunity costs -- spending more time with existing patients and seeing more patients. Not to mention less staff burnout and turnover.
            </p>

            <dl className="mt-4 space-y-4">
              {costState.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-0 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>


          </div>

        </div>



              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="">
                    <form action="#" method="POST" className="">
                        <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                            
        
                            <div className={styles.rangeSlider}>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Number of nurses
                                </label>
                                <input className={styles.rangeSliderRange} type="range" min="0" max="100" name="numberOfNurses" defaultValue={msgFormState.numberOfNurses} onMouseUp={handleSliderChange} onChange={handleSliderChange}/>
                                <span className={styles.rangeSliderValue}>{msgFormState.numberOfNurses}</span>
                            </div>

                            <div className={styles.rangeSlider}>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Number of doctors
                                </label>
                                <input className={styles.rangeSliderRange} type="range" min="0" max="75" name="numberOfDoctors" defaultValue={msgFormState.numberOfDoctors} onMouseUp={handleSliderChange} onChange={handleSliderChange}/>
                                <span className={styles.rangeSliderValue}>{msgFormState.numberOfDoctors}</span>
                            </div>


                            <div className={styles.rangeSlider}>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Portal messages per month
                                </label>
                                <input className={styles.rangeSliderRange} type="range" min="0" step="25" max="10000" name="numberOfMessagesPerMonth" defaultValue={msgFormState.numberOfMessagesPerMonth} onMouseUp={handleSliderChange} onChange={handleSliderChange}/>
                                <span className={styles.rangeSliderValue}>{msgFormState.numberOfMessagesPerMonth}</span>
                            </div>                    


                            
                            
                        </div>
                      
                        <div className="px-4 py-3 bg-gray-50 text-left sm:px-6">
                        <div className="">
      <div className="w-full max-w-md mt-2 mx-auto bg-white rounded-2xl">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-3 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <span>Customize</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-3 pt-4 pb-2 text-sm text-gray-500">
                <p>The starting estimates come from recent labor statistics, market reports, and our internal estimates. Go ahead and personalize these to see the savings for your clinic.</p>
                <p className="m3-6 my-2 text-sm text-gray-500">
              Don't know your clinic's numbers?{' '}
              <a href="/PilotSignUp" className="font-medium text-gray-700 underline">
                Use our pilot to find out!
              </a>
            </p>

                

                <div className={styles.rangeSlider}>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                  Avg. nurse salary
                                </label>
                                <input className={styles.rangeSliderRange} type="range" min="0" max="175" name="nurseSalary" defaultValue={msgFormState.nurseSalary} onMouseUp={handleSliderChange} onChange={handleSliderChange}/>
                                <span className={styles.rangeSliderValue}>${msgFormState.nurseSalary}k</span>
                            </div>    
        
                            <div className={styles.rangeSlider}>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Avg. physician salary
                                </label>
                                <input className={styles.rangeSliderRange} type="range" min="0" max="350" name="doctorSalary" defaultValue={msgFormState.doctorSalary} onMouseUp={handleSliderChange} onChange={handleSliderChange}/>
                                <span className={styles.rangeSliderValue}>${msgFormState.doctorSalary}k</span>
                            </div>    
        
                            <div className={styles.rangeSlider}>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Mins. spent per message (nurses)
                                </label>
                                <input className={styles.rangeSliderRange} type="range" min="0" max="30" name="nurseTimePerMessage" defaultValue={msgFormState.nurseTimePerMessage} onMouseUp={handleSliderChange} onChange={handleSliderChange}/>
                                <span className={styles.rangeSliderValue}>{msgFormState.nurseTimePerMessage}</span>
                            </div>    
                            
                            <div className={styles.rangeSlider}>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                  Mins. spent per message (doctors)
                                </label>
                                <input className={styles.rangeSliderRange} type="range" min="0" max="30" name="doctorTimePerMessage" defaultValue={msgFormState.doctorTimePerMessage} onMouseUp={handleSliderChange} onChange={handleSliderChange}/>
                                <span className={styles.rangeSliderValue}>{msgFormState.doctorTimePerMessage}</span>
                            </div>   
        
                            <div className={styles.rangeSlider}>
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Pct. of messages answered by nurses
                                </label>
                                <input className={styles.rangeSliderRange} type="range" min="0" max="100" name="pctAnsweredByNurses" defaultValue={msgFormState.pctAnsweredByNurses} onMouseUp={handleSliderChange} onChange={handleSliderChange}/>
                                <span className={styles.rangeSliderValue}>{msgFormState.pctAnsweredByNurses}%</span>
                            </div>   
                            <button
                              type="button"
                              className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              onClick={resetDefaults}
                            >
                  <RefreshIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                    Reset defaults
                 </button> 
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
       
      </div>
    </div>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
          </div>
        </div>
  
     
  

            
            
         
        
      </div>
      
    )
  }
  