import Header from "../components/Header"
import Footer from "../components/Footer"
import FreeTrialBanner from "../components/FreeTrialBanner"
import PricingTable from "../components/PricingTable"
import EstimatorForm from "../components/EstimatorForm"


export default function About() {
  return (
      <main> 
          <Header />
          <EstimatorForm />
          <FreeTrialBanner />
          {/* <PricingTable /> */}
          <Footer />
      </main>
  )
}