import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
// Default Splide theme
import '@splidejs/react-splide/css';

// Image imports
// import austinHardcastlePhoto from '../assets/people/austinHardcastle.png'
import austinHardcastlePhotoSquare from '../assets/people/austinHardcastle_sq.png'
import mohhGuptaPhoto from '../assets/people/mohhGupta.png'
// import tomScherrPhoto from '../assets/people/tomScherr.png'
import tomScherrPhotoSquare from '../assets/people/tomScherr_sq.png'
import johnKirbyPhoto from '../assets/people/johnKirby.png'
import robCroninPhoto from '../assets/people/robCronin.png'

import pragmaHealthFullBlue from '../assets/logos/pragmaHealth_full_blue.png'
import bsvLogo from '../assets/logos/bsv.png';
import osuwmcLogo from '../assets/logos/osuwmc.png';

export default function SplideTestimonial(props) {
  const quotes = [
    {
      key: 0,
      name: 'Tom Scherr',
      role: 'President & CEO',
      company: 'Pragma Health',
      personImage: tomScherrPhotoSquare,
      companyLogoImage: pragmaHealthFullBlue,
      quote: "Imagine getting the opportunity to push yourself with meaningful work everyday. And even better that I get to do it alongside a talented team of good people!",
    },
    {
      key: 1,
      name: 'John Kirby',
      role: 'Founder',
      company: 'Boat School Ventures',
      personImage: johnKirbyPhoto,
      companyLogoImage: bsvLogo,
      quote: "This team has really found a problem that resonates, and that's Business 101. They continue to execute at a high-level. Exciting things are ahead!",
    },
    {
      key: 2,
      name: 'Mohh Gupta',
      role: 'Data Science Engineer',
      company: 'Pragma Health',
      personImage: mohhGuptaPhoto,
      companyLogoImage: pragmaHealthFullBlue,
      quote: "Everyday I’m pushed to find new solutions to important problems. I feel like I’m going forward with every step, and learning new skills along the way.",
    },
    {
      key: 3,
      name: 'Austin Hardcastle',
      role: 'VP, Product',
      company: 'Pragma Health',
      personImage: austinHardcastlePhotoSquare,
      companyLogoImage: pragmaHealthFullBlue,
      quote: "It’s a great feeling to make tangible progress towards an amazing mission every day, and that’s what working at Pragma feels like!",
    },
    {
      key: 4,
      name: 'Rob Cronin, MD',
      role: 'Physician',
      company: 'The Ohio State University Wexner Medical Center',
      personImage: robCroninPhoto,
      companyLogoImage: osuwmcLogo,
      quote: "Doctors and nurses really do struggle to keep up with their patient inboxes. I'm looking forward to watching this technology develop and seeing the impact that it could have.",
    },
    
    
    // More people...
  ]

  return (
    <section className="bg-blue-800">
    <div className="max-w-7xl mx-auto md:px-6 lg:px-8">
    <Splide
      options={ {
        perPage: 1,
        rewind: true,
        gap   : '3rem',
        // focus  : 'center',
        type         : 'loop',
        // width: 800,
        autoplay     : true,
        pauseOnHover : true,
        resetProgress: false,
        pagination : true,
        arrows    : false,
        padding: '2rem',
        start  : Math.floor(Math.random() * quotes.length),
        speed: 600,
        autoScroll: {
          speed: 1,
        },
      } }
      aria-label="Team testimonials"
    >
    {quotes.map((quote) => (
      <SplideSlide key={quote.key}>
 
        <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:pr-16">
          <div className="md:flex-shrink-0">
            <img className="h-12" src={quote.companyLogoImage} alt={quote.company} />
          </div>
          <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
            <div className="relative text-lg font-medium text-white md:flex-grow">
              <svg
                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-blue-600"
                fill="currentColor"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                {quote.quote}
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex items-start">
                <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                  <img
                    className="h-12 w-12 rounded-full"
                    src={quote.personImage}
                    alt={quote.name}
                  />
                </div>
                <div className="ml-4">
                  <div className="text-base font-medium text-white">{quote.name}</div>
                  <div className="text-base font-medium text-blue-200">{quote.role}, {quote.company}</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
        
      </SplideSlide>

    ))}
      
      
    </Splide>
    </div>
    </section>
  );
}