/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { Formik } from 'formik';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import AlertBanner from './AlertBanner';


//TODO Tell us about yourself: Name, email address, title ... tell us about your clinic: name, EHR, speciality, staff size

export default function PilotContactForm() {

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
      status: null,
      color: null,
      header: null,
      text: null,
      handleDismiss: null,
  });

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [formSubmittedAlready, setFormSubmittedAlready] = useState(false);
 
  function dismissAlert() {
    setShowAlert(false)
  }

  function onCaptchaChange(value) {
    // console.log("Captcha value:", value);
    setIsCaptchaValid(true)
  }

  function onCaptchaExpire(value) {
    setIsCaptchaValid(false)
  }


  return (
    <section> 
    {showAlert && 
    <AlertBanner 
      status={alertProps.status}
      color={alertProps.color}
      header={alertProps.header}
      text={alertProps.text}
      handleDismiss={dismissAlert}
    /> }
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
      <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Let's get started!</h2>
            <p className="my-2 text-lg leading-6 text-gray-500">
            Our free pilot is designed to be a no-risk, 4-week evaluation. It's the starting point to reclaiming your staff time, and saving your clinic money!
            </p> 
            
          </div>
          <div className="mt-3 max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">How it works?</h2>
            
            <dl className="my-2 text-base text-gray-500">
              <div>
              <p className="text-lg leading-6 text-gray-500">After you share your contact information, we'll reach out to you to discuss the pilot program in more detail. We're happy to answer any questions you have, and we'll have a few questions of our own to make sure our product team is ready to handle implementation at your clinic. 
              </p>
           
                
              </div>
              
            </dl>
          </div>
          
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">

          <Formik
       initialValues={{
          fullName: '',
          organization: '',
          email: '',
          phone: '',
          message: '',
          page: 'PilotSignUpContact'
        }}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'An email address is required.';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Please enter a valid email address';
         }
         if (!values.organization) {
           errors.organization = 'An organization or company name is required.';
         } 
         if (!values.fullName) {
           errors.fullName = 'A name is required.';
         } 
         if (!values.phone) {
           errors.phone = 'A phone number is required.';
         } else if (
           !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(values.phone)
         ) {
           errors.phone = "Please enter a valid phone number."
         }
         
         return errors;
       }}
       onSubmit={(values, { setSubmitting }) => {
        // Handle state and form button
        setSubmitting(false);
        setFormSubmittedAlready(true);
        //  Logic to make post request
        // e.preventDefault();
            fetch('https://azl4ymdkbb.execute-api.us-east-1.amazonaws.com/default/sendContactEmail', {
            method: 'POST', // or 'PUT'
            // headers: {
            //   'Content-Type': 'application/json',
            // },
            body: JSON.stringify(values),
          })
          .then(response => response.json())
          .then(data => {
            // AWS SES in Lambda will always return something, so we need to parse here 
            if ('MessageId' in data) {
                // console.log('Success:', data);
              // Do something with success
              setAlertProps({
                status:"success",
                color:"green",
                header:"Your contact form has been received!",
                text:"A member of our team will reach out to you directly.",
              })
              setShowAlert(true)
            } else {
                setAlertProps({
                status:"error",
                color:"pink",
                header:"There was a problem submitting your form.",
                text:"Please try again.",
              })
              setShowAlert(true)
              setFormSubmittedAlready(false);
            }
            
          })
          .catch((error) => {
            // console.error('Error:', error);
            // Do something with error
            setAlertProps({
              status:"error",
              color:"pink",
              header:"There was a problem submitting your form.",
              text:"Please try again.",
            })
            setShowAlert(true)
            setFormSubmittedAlready(false);
          });

       }}
     >

{({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         isValid,
         dirty
         /* and other goodies */
       }) => (
         <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-2">
         <label htmlFor="fullName" className="sr-only">
                  Full name
          </label>
         <input
             type="text"
             name="fullName"
             placeholder="Full Name"
             className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.fullName}
           />
           <div className="text-sm text-red-400 pl-2">
           {errors.fullName && touched.fullName && errors.fullName}
           </div>
           <label htmlFor="organization" className="sr-only">
                  Organization Name
          </label>
          <input
             type="text"
             name="organization"
             autoComplete="organization"      
             placeholder="Organization Name"
             className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.organization}
           />
           <div className="text-sm text-red-400 pl-2">
           {errors.organization && touched.organization && errors.organization}
           </div>
           <label htmlFor="email" className="sr-only">
                  Email
          </label>
           <input
             type="email"
             name="email"
            autoComplete="email"
            placeholder="Email"
            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.email}
           />
           <div className="text-sm text-red-400 pl-2">
           {errors.email && touched.email && errors.email}
           </div>
           <label htmlFor="phone" className="sr-only">
                  Phone
          </label>
           <input
            type="tel"
            name="phone"
            placeholder="Phone"
            autoComplete="tel"
            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.phone}
           />
           <div className="text-sm text-red-400 pl-2">
           {errors.phone && touched.phone && errors.phone}
           </div>
           <label htmlFor="message" className="sr-only">
                  Message
            </label>
            <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md"
                  placeholder="What can we do for you?"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
            />
            <div className="text-sm text-red-400 pl-2">
            {errors.message && touched.message && errors.message}
            </div>
            <ReCAPTCHA
                sitekey="6Ldc_3gfAAAAAC778MN-e7VL8MdzJRqUbATBzIoq"
                onChange={onCaptchaChange}
                onExpired={onCaptchaExpire}
              />
                
            
              <div> 
           <button type="submit" 
           disabled={!isCaptchaValid || formSubmittedAlready || (!isValid || isSubmitting || !dirty) || formSubmittedAlready}
           className={`inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 
                  ${isCaptchaValid && !formSubmittedAlready && (isValid && !isSubmitting && dirty) ? "focus:ring-blue-500 bg-blue-600 hover:bg-blue-700" : "focus:ring-gray-500 bg-gray-600 hover:bg-gray-700"}`}     
           >
             Submit
           </button>
           </div>
         </form>
       )}

             
            
              
            </Formik>
          </div>
        </div>
      </div>
    </div>
    </section>
  )
}
