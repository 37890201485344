// import Home from "./Home"
// import Contact from "./Contact"
import Header from "../components/Header"
import Footer from "../components/Footer"
import MissingBlock from "../components/MissingBlock"
// import { Link } from "react-router-dom"


/* This example requires Tailwind CSS v2.0+ */
export default function Error() {
    return (
      <main>
        <Header />
        <MissingBlock 
          sideText="404"
          headerText="Page not found"
          subHeaderText="Please check the URL in the address bar and try again."
        />
        <Footer />
      </main>
    )
  }
  