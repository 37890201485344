/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, ShieldCheckIcon, LightningBoltIcon, ScaleIcon, CashIcon, DocumentReportIcon, CheckIcon, ClipboardCheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Absolutely free!',
    description:
      'There is no charge, at all. Seriously! We will even pay any of your associated IT costs with implementation.',
    icon: CashIcon,
  },
  {
    name: 'Secure',
    description:
      'Industry leading cyber-security firms have certified our team and our platform as HIPAA and SOC 2 compliant.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Personalized Report',
    description:
      "At the end of the pilot, you'll receive our full analytics report containing aggregated and individualized insight. Also learn where our other products could help.",
    icon: DocumentReportIcon,
  },
  {
    name: 'No long-term commitments',
    description:
      "We are confident that you'll see the value in our services. But at the end of the pilot, if it's not for you, you'll still receive your full report.",
    icon: ClipboardCheckIcon,
  },
]

export default function ObserveReportFeatures() {
  return (
    <div className="py-12 bg-white" id="ONRPilot">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Observe & Report Pilot</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Understand your inbox needs
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Our O&R platform is designed to help you understand what your current messaging landscape looks like. Who is doing the heavy lifting? How much time is your staff spending on routine questions? How satisfied are your patients with your responses?
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
