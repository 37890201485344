// import Home from "./Home"
// import Contact from "./Contact"
import Header from "../components/Header"
import Footer from "../components/Footer"
import MissingBlock from "../components/MissingBlock"
import { BeakerIcon } from "@heroicons/react/outline"
import PilotContactForm from "../components/PilotContactForm"
// import { Link } from "react-router-dom"


/* This example requires Tailwind CSS v2.0+ */
export default function PilotSignUp() {
    return (
      <main>
        <Header />
        <PilotContactForm />
        <Footer />
      </main>
    )
  }
  