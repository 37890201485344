import Header from '../components/Header'
import Footer from '../components/Footer'
import ObserveReportFeatures from '../components/ObserveReportFeatures'
import EfficiencyFeatures from '../components/EfficiencyFeatures'
import IntellireplyFeatures from '../components/IntellireplyFeatures'
import ReportingFeatures from '../components/ReportingFeatures'
import PilotCTA from '../components/PilotCTA'


export default function About() {
    return (
        <main> 
            <Header />
            <ObserveReportFeatures />
            <PilotCTA />
            <EfficiencyFeatures />
            <IntellireplyFeatures />
            <ReportingFeatures />
            <Footer />
        </main>
    )
};