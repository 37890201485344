/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { HashLink } from 'react-router-hash-link';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function SimpleBanner() {
  return (
    <div className="relative bg-gray-50 overflow-hidden">
      

      <div className="relative pt-6 pb-16 sm:pb-24">
       

        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Building a</span>{' '}
              {/* <span className="block xl:inline underline">smarter</span>
              <span className="block xl:inline">,</span>{' '} */}
              <span className="block text-blue-600 xl:inline">better clinical inbox</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Keeping up with your portal messages doesn't have to be a struggle. 
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <HashLink
                smooth
                key="/#LearnMore"
                to="/#LearnMore"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                >
                Learn More
                </HashLink>
              </div>
             
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a href="/PilotSignUp" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
                  Join Our Pilot
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
